<template>
    <div class="hero-section">
        <div class="effects">
            <div class="effact one">
                <img src="../assets/images/laravel.svg" loading="lazy" alt class="effect-img" />
            </div>
            <div class="effact tow">
                <img src="../assets/images/vue.svg" loading="lazy" alt class="effect-img" />
            </div>
            <div class="effact three">
                <img src="../assets/images/python.svg" loading="lazy" alt class="effect-img" />
            </div>
            <div class="effact four">
                <img src="../assets/images/wordpress-plain.svg" loading="lazy" alt class="effect-img" />
            </div>
            <div class="effact five">
                <img src="../assets/images/linux.svg" loading="lazy" alt class="effect-img" />
            </div>
        </div>
        <div class="container hero w-container">
            <div class="hero-section--inner">
                <h3 class="hero--heading">
                    Hello I&#x27;m Ahmad
                    <span class="text-span">Eyamin</span>
                </h3>
                <h1 class="hero--title">Young Web Programmer 👌</h1>
                <h3 class="hero--base">
                    Freelance
                    <span class="text-span-2">Web Designer/Developer</span> &amp; Love to make
                    Friends ✨
                </h3>
                <div class="hero--links">
                    <a
                        href="http://github.com/ahmadeyamin"
                        target="_blank"
                        class="hero--link w-inline-block"
                    >
                        <img src="../assets/images/github.svg" loading="lazy" alt class="hero--link-image" />
                    </a>
                    <a
                        href="http://fb.me/ahmadeyamin"
                        target="_blank"
                        class="hero--link w-inline-block"
                    >
                        <img
                            src="../assets/images/fb.png"
                            loading="lazy"
                            width="40"
                            sizes="45px"
                            alt
                            class="hero--link-image"
                        />
                    </a>
                    <a
                        href="http://twitter.com/ahmadeyamin"
                        target="_blank"
                        class="hero--link w-inline-block"
                    >
                        <img src="../assets/images/twitter.svg" loading="lazy" alt class="hero--link-image" />
                    </a>
                </div>
            </div>
            <div class="hero--my-image">
                <div class="hero_image-src">
                    <img
                        src="../assets/images/Businessman-Transparent.png"
                        loading="lazy"
                        alt="Ahmad Eyamin"
                        class="hero-image"
                    />
                </div>
                <div class="hero--hireme-section">
                    <div class="hero--hireme-content">
                        <a
                            href="https://fiverr.com/itechut"
                            target="_blank"
                            class="hireme--link-item text w-inline-block"
                        >
                            <div class="hireme--link-text">Hire Me</div>
                        </a>
                        <a
                            href="tel:+8801999050360"
                            class="hireme--link-item transition w-inline-block"
                        >
                            <img src="../assets/images/telephone.svg" loading="lazy" alt class="image" />
                        </a>
                        <a
                            href="https://dribbble.com/ahmadeyamin"
                            target="_blank"
                            class="hireme--link-item transition w-inline-block"
                        >
                            <img src="../assets/images/dribbble.svg" loading="lazy" alt class="image" />
                        </a>
                        <a
                            href="https://wa.me/+8801999050360"
                            target="_blank"
                            class="hireme--link-item transition w-inline-block"
                        >
                            <img src="../assets/images/whatsapp.svg" loading="lazy" alt class="image" />
                        </a>
                    </div>
                </div>
                <div class="hero-exp">
                    <div class="hero-exp--inner">
                        <h1 class="hero-exp-y">4</h1>
                        <p class="heros-exp--text">Years of experience</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-2">
        <div class="sec-2--inner container w-container">
            <div class="sec-2--content">
                <div class="sec_2--c-item">
                    <div class="sec_2--left">
                        <div class="sec_2-left-item transition">
                            <div class="sec_2-ll-inner-left">
                                <h2 class="sec_2--left-title">Design</h2>
                                <p
                                    class="sec_2--left-text"
                                >Design website with unique idea and grow user engagements</p>
                                <a href="#" class="sec_2--left-link">20+ Projects</a>
                            </div>
                            <div class="sec_2-ll-inner-right">
                                <img
                                    src="../assets/images/web-design.svg"
                                    loading="lazy"
                                    alt
                                    class="sec_2-left-icon"
                                />
                            </div>
                        </div>
                        <div class="sec_2-left-item transition">
                            <div class="sec_2-ll-inner-left">
                                <h2 class="sec_2--left-title">Front-End 😍</h2>
                                <p
                                    class="sec_2--left-text"
                                >I develop front-end with coding supper smooth</p>
                                <a href="#" class="sec_2--left-link">40+ Projects</a>
                            </div>
                            <div class="sec_2-ll-inner-right">
                                <img
                                    src="../assets/images/css.svg"
                                    loading="lazy"
                                    alt
                                    class="sec_2-left-icon"
                                />
                            </div>
                        </div>
                        <div class="sec_2-left-item transition">
                            <div class="sec_2-ll-inner-left">
                                <h2 class="sec_2--left-title">Development</h2>
                                <p class="sec_2--left-text">Make front end alive with programming</p>
                                <a href="#" class="sec_2--left-link">15+ Projects</a>
                            </div>
                            <div class="sec_2-ll-inner-right">
                                <img
                                    src="../assets/images/idea.svg"
                                    loading="lazy"
                                    alt
                                    class="sec_2-left-icon"
                                />
                            </div>
                        </div>
                        <div class="sec_2-left-item transition">
                            <div class="sec_2-ll-inner-left">
                                <h2 class="sec_2--left-title">SEO</h2>
                                <p
                                    class="sec_2--left-text"
                                >Boost product with online SEO Marketing and optimization</p>
                                <a href="#" class="sec_2--left-link">5+ Projects</a>
                            </div>
                            <div class="sec_2-ll-inner-right">
                                <img
                                    src="../assets/images/graph-bar.svg"
                                    loading="lazy"
                                    alt
                                    class="sec_2-left-icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sec_2--c-item">
                    <div class="sec_2--c-right-inner">
                        <h4 class="sec_2--right-heading">Introduce</h4>
                        <h2 class="sec_2-right-title">I&#x27;m Ahmad Eyamin 🙌</h2>
                        <h3 class="sec_2-right-sub">Every Great idea comes with a Better story.</h3>
                        <p class="sec_2-right-p">
                            Since beginning my journey as a freelance designer/developer over 3 years. I have
                            done some remote work for Business, Startup, Company and lot&#x27;s of local client in my smell
                            journey.
                            <br />‍
                            <br />I&#x27;m quality confident, naturally curious, and working on improving my Skill for
                            design/development.
                            <br />
                            <br />
                            <br />Experience in:
                            <br />
                            <strong></strong>
                            <span class="exp-item-p">
                                - Web
                                Design
                            </span>
                            <br />
                            <span class="exp-item-p">- Web Development</span>
                            <br />
                            <span class="exp-item-p">
                                -
                                WordPress
                            </span>
                            <br />
                            <span class="exp-item-p">- SEO</span>
                            <br />
                            <span class="exp-item-p">
                                - Social Media
                                Marketing (Basic)
                            </span>
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-3">
        <div class="sec-3--inner container w-container">
            <h1 class="sec-2_title">Tool I Use ❤</h1>
            <SkillesIcons/>
        </div>
    </div>
    <div id="portfolio" class="section-4">
        <div class="sec-4 container w-container">
            <div class="sec-4-inner">
                <div class="sec_4--item">
                    <div class="sec_4--item-inner">
                        <h1 class="sec-4__title">Portfolio</h1>
                    </div>
                </div>
                <div class="sec_4--item">
                    <div class="sec_4--item-inner">
                        <img
                            src="images\photo-of-designer-creating-a-website.jpeg"
                            loading="lazy"
                            alt
                            class="portfolio-img transition"
                        />
                        <div class="portfolio-box-content">
                            <h1 class="portfolio-box-title">Heading</h1>
                            <p class="portfolio-box-text">Web For All</p>
                            <a href="#" class="see-more-btn">View Work</a>
                        </div>
                    </div>
                </div>
                <div class="sec_4--item">
                    <div class="sec_4--item-inner">
                        <img
                            src="../assets/images/Maria-Vazquez-website.jpeg"
                            loading="lazy"
                            alt
                            class="portfolio-img transition"
                        />
                        <div class="portfolio-box-content">
                            <h1 class="portfolio-box-title">Maria Work</h1>
                            <p class="portfolio-box-text">Web For All</p>
                            <a href="#" class="see-more-btn">View Work</a>
                        </div>
                    </div>
                </div>
                <div class="sec_4--item">
                    <div class="sec_4--item-inner">
                        <img
                            src="../assets/images/Juliette-van-Rhyn-website.jpeg"
                            loading="lazy"
                            alt
                            class="portfolio-img transition"
                        />
                        <div class="portfolio-box-content">
                            <h1 class="portfolio-box-title">Art Work</h1>
                            <p class="portfolio-box-text">Web For All</p>
                            <a href="#" class="see-more-btn">View Work</a>
                        </div>
                    </div>
                </div>
                <div class="sec_4--item">
                    <div class="sec_4--item-inner">
                        <img
                            src="../assets/images/kate-moross-website.jpeg"
                            loading="lazy"
                            alt
                            class="portfolio-img transition"
                        />
                        <div class="portfolio-box-content">
                            <h1 class="portfolio-box-title">Personal Pro</h1>
                            <p class="portfolio-box-text">Web For All</p>
                            <a href="#" class="see-more-btn">View Work</a>
                        </div>
                    </div>
                </div>
                <div class="sec_4--item">
                    <a href="#" class="sec_4--item-inner w-inline-block">
                        <h1 class="sec-4__title bottom">See More Work ...</h1>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section-5">
        <div class="sec-5 container w-container">
            <div class="sec-5_inner">
                <h1 class="sec-5__title">Testimonial</h1>
                <div class="sec-5__testi_box">
                    <div class="sec-5__testi_box--inner">
                        <div class="testi_item">
                            <p class="testi_text">
                                He did a really good job and worked quickly it was the best developer I&#x27;ve
                                worked with on the site. Fantastic to work with, accommodating for changes as we worked, fast worker and
                                a good communicator. Very happy! Great stuff.
                            </p>
                            <p class="testi_buyer_name">
                                sanslr28
                                <span class="testi_buyer-s">- Fiverr</span>
                            </p>
                        </div>
                    </div>
                    <div class="sec-5__testi-btns">
                        <a href="#" class="testi_slide_btn"></a>
                        <a href="#" class="testi_slide_btn right"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-6">
        <div class="sec-6 container w-container">
            <div class="sec_6--inner">
                <div class="sec_6-box">
                    <h1 class="sec_6-title">
                        Let&#x27;s Talk
                        <br />
                    </h1>
                    <div class="sec-6_contact_box">
                        <div class="sec-6_in_left">
                            <div class="main_cont_box">
                                <h1 class="sec_6-box-title">
                                    Get in touch
                                    <br />‍
                                </h1>
                                <div class="contact_form w-form">
                                    

                                    <ContactForm/>



                                    <div class="w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div class="w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sec-6_in_right">
                            <div class="contact_right_p">
                                <a
                                    href="https://goo.gl/maps/uuEcT6FA9o8soNBs8"
                                    target="_blank"
                                    class="contact_right_item w-inline-block"
                                >
                                    <h1 class="cont_right_title">Policeline, Bhola Sadar, BD</h1>
                                    <div class="cont_right_deg">
                                        <img
                                            src="../assets/images/earth-globe.svg"
                                            loading="lazy"
                                            alt
                                            class="cont_right_deg_icon"
                                        />
                                        <p class="cont_right_deg_p">Home Address</p>
                                    </div>
                                </a>
                                <a
                                    href="mailto:ahmadeyamin@gmail.com"
                                    class="contact_right_item w-inline-block"
                                >
                                    <h1 class="cont_right_title">ahmadeyamin@gmail.com</h1>
                                    <div class="cont_right_deg">
                                        <img
                                            src="../assets/images/email-marketing.svg"
                                            loading="lazy"
                                            alt
                                            class="cont_right_deg_icon"
                                        />
                                        <p class="cont_right_deg_p">Email Address</p>
                                    </div>
                                </a>
                                <a
                                    href="tel:+8801999050360"
                                    class="contact_right_item w-inline-block"
                                >
                                    <h1 class="cont_right_title">+8801999050360</h1>
                                    <div class="cont_right_deg">
                                        <img
                                            src="../assets/images/telephone_2.svg"
                                            loading="lazy"
                                            alt
                                            class="cont_right_deg_icon"
                                        />
                                        <p class="cont_right_deg_p">official Phone</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <HomeBlogPosts/>
</template>

<script>
// @ is an alias to /src
import SkillesIcons from '../components/SkillesIcons'
import HomeBlogPosts from '../components/HomeBlogPosts'
import ContactForm from '../components/Contact'
export default {
    name: "Home",
    components: {
        SkillesIcons,
        HomeBlogPosts,
        ContactForm
    },
};
</script>
